import { useQuery, queryOptions } from '@tanstack/vue-query'
import type { ILeaseInfo } from '@register'
import type { ApiError } from '@shared'

export function apiLeaseInfoOptions({
  divisionId,
  leaseId,
}: {
  divisionId: string
  leaseId: string
}) {
  return queryOptions({
    queryKey: ['companies', divisionId, 'leases', leaseId],
    queryFn: () =>
      fetchAPI<ILeaseInfo>(`/companies/${divisionId}/leases/${leaseId}`),
    throwOnError: true,
  })
}

/*
 * @returns Information needed to mount the lease page
 */
export const useApiLeaseInfo = async (divisionId: string, leaseId: string) => {
  const query = useQuery(apiLeaseInfoOptions({ divisionId, leaseId }))

  try {
    await query.suspense()
    return query
  } catch (e) {
    throw showError(e as ApiError)
  }
}
